<template>
  <v-layout wrap fill-height align-center justify-center>
    <v-container :class="$vuetify.breakpoint.xs ? 'pa-0' : ''" fluid>
      <v-layout v-if="authStatus !== 'fetching'" align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-dialog
            :value="authStatus === 'multiple_residents'"
            persistent
            max-width="500px"
          >
            <v-card flat>
              <v-card-title primary-title>{{
                $t("billPreview.title")
              }}</v-card-title>
              <v-card-text class="pa-0">
                <v-list
                  v-if="
                    authResult && authResult.checkin && authResult.checkin[0]
                  "
                >
                  <template
                    v-for="(person, index) in authResult.checkin[0].residents"
                  >
                    <v-divider
                      :key="'divider-' + index"
                      v-if="index > 0"
                    ></v-divider>
                    <v-list-item
                      :key="'person-' + index"
                      @click="authenticateById(person)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ person.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-card
            :flat="$vuetify.breakpoint.xs"
            :color="$vuetify.breakpoint.xs ? 'transparent' : undefined"
          >
            <v-card-text></v-card-text>
            <v-img
              contain
              class="mb-3 mt-3"
              v-if="theme.logoImage"
              height="80px"
              :src="root + 'media' + theme.logoImage"
              :lazy-src="root + 'media' + theme.logoImage"
            ></v-img>
            <v-card-text>
              <language-selection
                :outlined="true"
                :show-label="true"
                :show-img="true"
              ></language-selection>
            </v-card-text>
            <v-alert type="error" :value="showAlert">{{
              $t("login.room_or_name_invalid")
            }}</v-alert>
            <v-card-text>
              <v-form ref="login" v-model="valid" @submit="login">
                <v-text-field
                  outlined
                  @keydown.native.enter="login"
                  append-icon="mdi-account"
                  name="login"
                  :label="$t('login.user')"
                  type="text"
                  v-model="name"
                  required
                  :rules="nameRules"
                ></v-text-field>
                <v-text-field
                  outlined
                  @keydown.native.enter="login"
                  append-icon="mdi-map-marker"
                  name="password"
                  :label="$t('login.room')"
                  id="password"
                  type="text"
                  v-model="room"
                  required
                  :rules="roomRules"
                ></v-text-field>
                <base-date-picker
                  v-if="$config.APP_LOGIN_TYPE === 'lastname+checkin'"
                  required
                  :rules="checkinDateRequiredRules"
                  v-model="checkinDate"
                  :label="$t('login.checkinDate')"
                ></base-date-picker>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                large
                :loading="
                  authStatus === 'fetching' || onesocketStatus !== 'success'
                "
                color="primary"
                block
                @click="login"
                >{{ $t("login.signin") }}</v-btn
              >
            </v-card-actions>
            <v-card-actions v-if="isWebcheckinEnabled">
              <v-btn
                large
                block
                outlined
                color="primary"
                @click="redirectToWebcheckin()"
                :loading="showLoadingRedirectToWebcheckin"
                >{{ $t("login.checkin") }}</v-btn
              >
            </v-card-actions>
            <v-card-actions v-if="hasWalkin">
              <v-btn
                large
                block
                text
                color="primary"
                @click="continueWithoutLogin()"
                :loading="showLoadingContinueWithoutLogin"
                >{{ $t("login.walkin") }}</v-btn
              >
            </v-card-actions>
          </v-card>
          <!--<v-footer
            class="text-center"
            app
            v-if="$route && $route.name === 'login'"
          >
            <v-container class="pa-0">{{$t('app.poweredBy')}}</v-container>
          </v-footer>-->
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LanguageSelection from "@/components/LanguageSelection";
import BaseDatePicker from "@/components/base/BaseDatePicker.vue";
import { ROOT } from "@/config";

export default {
  name: "Login",
  components: {
    LanguageSelection,
    BaseDatePicker,
  },
  mounted() {
    this.setLoginDataFromQuery();
  },
  computed: {
    ...mapState({
      hasWalkin: ({ session }) => session.hasWalkInContent,
      authStatus: ({ session }) => session.status,
      authResult: ({ session }) => session.authResult,
      languageList: ({ language }) => language.languageList,
      theme: ({ theme }) => theme.theme,
      onesocketStatus: ({ session }) => session.onesocketStatus,
      isWebcheckinEnabled: ({ session }) => session.isWebcheckinEnabled,
    }),
    showAlert() {
      return (
        this.authStatus !== "fetching" &&
        this.authStatus === "not_registered" &&
        this.loginBtnPressed
      );
    },
  },
  data() {
    return {
      showLoadingContinueWithoutLogin: false,
      showLoadingRedirectToWebcheckin: false,
      name: "",
      room: "",
      loginBtnPressed: false,
      valid: false,
      nameRules: [(v) => !!v || this.$t("general.nameRequired")],
      roomRules: [(v) => !!v || this.$t("general.roomRequired")],
      checkinDateRequiredRules: [
        (v) => !!v || this.$t("general.checkinDateRequired"),
      ],
      root: ROOT,
      dateModal: false,
      checkinDate: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && from.name !== "login" && to.query.redirect !== from.path) {
      next({ path: to.path, query: { ...to.query, redirect: from.path } });
      return;
    }
    next();
  },
  /*
  beforeRouteLeave(to, from, next) {
    if (to.name !== "login" && from.query.redirect) {
      setTimeout(() => {
        this.$router.push({ path: from.query.redirect });
      }, 300);
    }
    next();
  },
  */
  methods: {
    ...mapMutations("session", {
      setMode: "SET_MODE",
    }),
    ...mapActions("session", [
      "authenticate",
      "setLoginData",
      "authenticateById",
      "goToWebcheckin",
    ]),
    continueWithoutLogin() {
      this.showLoadingContinueWithoutLogin = true;
      this.setMode("walkin");
      this.$router.push({
        query: { ...this.$route.query, mode: "walkin" },
      });
      this.authenticate({});
    },
    redirectToWebcheckin() {
      this.showLoadingRedirectToWebcheckin = true;
      this.goToWebcheckin();
    },
    login() {
      if (this.valid) {
        this.setLoginData({
          room: this.room,
          name: this.name,
          checkin: this.checkinDate,
        });

        this.authenticate({
          room: this.room,
          name: this.name,
          checkin: this.checkinDate,
        });

        this.loginBtnPressed = true;
      } else {
        this.$refs.login.validate();
      }
    },
    setLoginDataFromQuery() {
      const { room = "", name = "", checkin = "" } = this.$route.query;
      if (
        (room || name || checkin) &&
        room != "null" &&
        name != "null" &&
        checkin != "null"
      ) {
        this.room = room;
        this.name = name;
        this.checkinDate = checkin;
      }
    },
  },
  watch: {
    "$route.query"() {
      this.setLoginDataFromQuery();
    },
  },
};
</script>
