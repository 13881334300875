<template>
  <v-dialog
    ref="dialog"
    v-model="dialog"
    :return-value.sync="computedValue"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-bind="attrs"
        v-model="computedValue"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        :required="required"
        v-on="readonly ? '' : on"
        :rules="rules"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="datePicker"
      v-model="computedValue"
      scrollable
      :locale="$store.state.session.browserLang"
      color="primary"
      @change="
        (date) => {
          $refs.dialog.save(date);
        }
      "
    ></v-date-picker>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";

export default {
  data: () => ({
    dialog: false,
  }),
  mounted() {
    if (!this.computedValue) {
      this.computedValue = DateTime.fromJSDate(new Date()).toFormat(
        "yyyy-MM-dd"
      );
    }
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    dialog(val) {
      val && setTimeout(() => (this.$refs.datePicker.activePicker = "YEAR"));
    },
  },
};
</script>
